import { AgencyDetails } from '../../dashboard/getPropertyDetails/GetPropertyDetails.data';
import { PhotoLink } from '../../fileUpload/FileData';
import { PaymentInfo, PaymentTransactions, RentalIncrease, RentSplit } from '../payment/Payment';
import { Template, TermWithCategoryIds } from '../template/Template';
import { GetUserResponse } from '../user/User';

export enum PropertyType {
  House = 'House',
  Townhouse = 'Townhouse',
  Apartment = 'Apartment',
  Villa = 'Villa',
  Unit = 'Unit',
  Flat = 'Flat',
  Studio = 'Studio',
  Warehouse = 'Warehouse',
  DuplexSemiDetached = 'DuplexSemi-detached',
  Alpine = 'Alpine',
  AcreageSemiRural = 'AcreageSemi-rural',
  BlockOfUnits = 'BlockOfUnits',
  Terrace = 'Terrace',
  Retirement = 'Retirement',
  ServicedApartment = 'ServicedApartment',
  Duplex = 'Duplex',
  Acreage = 'Acreage',
  Commercial = 'Commercial',
  CommercialFarming = 'Commercial-Farming',
  IndustrialWarehouse = 'Industrial-Warehouse',
  Offices = 'Offices',
  Retail = 'Retail',
  Rural = 'Rural',
  Other = 'Other',
}

export enum RentalFrequency {
  Weekly = 'Weekly',
  Fortnightly = 'Fortnightly',
  Monthly = 'Monthly',
}

export enum PropertySource {
  Palace = 'PALACE',
  Airtable = 'AIRTABLE',
  ReaXML = 'REAXML',
  Portal = 'PORTAL',
  SortedV1 = 'SORTEDV1',
  PropertyMe = 'PROPERTYME',
}
export interface Property {
  id: number;
  address: string;
  type: PropertyType | undefined | null;
  customAddressField: string | undefined | null;
  bedrooms: number | undefined | null;
  bathrooms: number | undefined | null;
  carspaces: number | undefined | null;
  description: string | undefined | null;
  managementFee: number | undefined | null;
  commissionType: string | undefined | null;
  secondaryAgents: GetUserResponse[] | undefined | null;
  landlords: GetUserResponse[] | undefined | null;
  managingAgent: GetUserResponse | undefined | null;
  leasingAgent: GetUserResponse | undefined | null;
  photos: string[] | undefined | null;
  unitNumber: string | undefined | null;
  streetName: string | undefined | null;
  suburb: string | undefined | null;
  postcode: string | undefined | null;
  streetNumber: string | undefined | null;
  state: string | undefined | null;
  country: string | undefined | null;
  stateBondOffice: string | undefined | null;
  source: PropertySource | undefined | null;
  refId: string | null;

  waterChargesRequired?: boolean | null;
  embeddedNetworksConifg?: NetworkValues;
}

export interface PropertyPhotos {
  link: string;
}

export interface NetworkValue {
  serviceType: string;
  provider: string | null;
}

export type NetworkValues = NetworkValue[];

export interface UsersInBond {
  userId: number;
  onBond: boolean;
}

export interface LeaseAttachment {
  id: number;
  title: string | undefined | null;
  fileName: string | undefined | null;
  link: string | undefined | null;
  version: number | undefined | null;
  lastModifiedDate: string | undefined | null;
}

export interface LeaseTerms {
  leaseTermsOrder: number;
  terms: TermWithCategoryIds;
  isUpdated?: boolean;
  termStatus?: string;
}

export interface LeaseSigningDocument {
  id: number | undefined | null;
  leaseSigningDocumentTerm: LeaseTerms[];
  templateInfo: Template;
  newVersion?: boolean | undefined;
}

export class LeaseConfigurableDocument {
  id: number | undefined | null;
  leaseConfigurableDocumentTerm: LeaseTerms[];
  templateInfo: Template;
  newVersion?: boolean | undefined;
}

export interface BondForm {
  id: number;
  title: string | undefined | null;
  originalFilename: string | undefined | null;
  link: string | undefined | null;
  publishVersion: number | undefined | null;
}

export interface LeaseUploadTemplate {
  id: number;
  title: string | undefined | null;
  originalFilename: string | undefined | null;
  link: string | undefined | null;
  publishVersion: number | undefined | null;
}

export interface LeaseDisclosureStatementResponse {
  id: number;
  templateInfo: Template;
}

export interface DisclosureStatementResponse {
  link: string | null;
  leaseId: number | null;
  appointmentDateTime: string;
  appointmentId: number;
  landlords: GetUserResponse[];
  owners: GetUserResponse[];
  preSigned: boolean;
}

export interface LeaseDisclosureStatementRequest {
  templateId: number;
}

export interface PdfFormTemplate {
  id: number;
  title?: string | null;
  originalFilename?: string | null;
  link?: string | null;
  publishVersion?: number | null;
}

export interface LeaseTenancyAgreement {
  id: number | undefined | null;
  templateInfo: Template;
  leaseAgreementTermResponse: LeaseTerms[];
  newVersion?: boolean | undefined;
}

export enum RenewalType {
  Standard = 'Standard',
  Continuation = 'Continuation',
  Periodic = 'Periodic',
  PortalRenewal = 'Portal Renewal',
}

interface ArchiveNotificationList {
  leaseAgreement: number[];
  leaseConfigurableDocument: number[];
  leaseSigningDocument: number[];
  uploadTemplate: number[];
}

interface RentReviewLeaseResponse {
  type: string | null;
  lastRentStartDate: string | null;
}

interface ApplicationResponse {
  applicants: ApplicantResponse[];
}

export interface ApplicantResponse {
  firstName: string;
  lastName: string;
  dob: string | undefined | null;
  applicantType: string;
}

export interface RentReviewData {
  amount: number | null;
  frequency: string | null;
  reviewDate: string;
}

export enum RentIncreaseLimitType {
  DOLLAR = '$',
  PERCENTAGE = '%',
  CPI = 'CPI',
  MRR = 'MRR',
}

export interface RentalIncreases {
  rentIncrease: boolean;
  rentIncreaseFirstAmount: number | undefined | null;
  rentIncreaseFirstOneCycleAmount: number | undefined | null;
  rentIncreaseFirstDate: string | undefined | null;
  rentIncreaseSecondAmount: number | undefined | null;
  rentIncreaseSecondOneCycleAmount: number | undefined | null;
  rentIncreaseSecondDate: string | undefined | null;
  fixedRentIncrease: boolean;
  rentIncreaseLimit?: string | null;
  rentIncreaseLimitType?: string | null;
}

export interface RentalAccountInfo {
  rentalAmount: number | null;
  rentalFrequency: string | null;
  status: string | null;
}

export interface LeaseServiceabilityResponse {
  serviceType: string;
  serviceability: Serviceability;
  serviceProvider: ServiceProvider;
}

export interface ServiceProvider {
  id: number;
  name: string;
  icon: string | null;
}

export interface TenantAddresses {
  [key: string]: string;
}

export enum Serviceability {
  OPT_MANDATORY = 'OPT_MANDATORY',
  OPT_YES = 'OPT_YES',
  OPT_NO = 'OPT_NO',
}

export interface GetLeaseResponse {
  id: number;
  property: Property | undefined | null;
  tenants: GetUserResponse[] | undefined | null;
  rentSplitPercentage?: RentSplit[];
  primaryTenant: GetUserResponse | undefined | null;
  rentalAmount: number | undefined | null;
  servicesComplete: string[];
  oneCycleRentalAmount: number | undefined | null;
  firstRentalIncreaseAmountOneCycle: number | undefined | null;
  secondRentalIncreaseAmountOneCycle: number | undefined | null;
  bond: number | undefined | null;
  urgentRepairsAmount: number | undefined | null;
  rentalFrequency: RentalFrequency | string | undefined | null;
  dateAvailable: string | undefined | null;
  leaseDuration: number | undefined | null;
  startDate: string | undefined | null;
  endDate: string | undefined | null;
  moveInDate: string | undefined | null;
  premisesProvidedWith: string | undefined | null;
  adultsPermitted: number | undefined | null;
  childrenPermitted: number | undefined | null;
  petPermitted: boolean | undefined | null;
  allowedPetsInfo: string | undefined | null;
  inspectionDate: string | undefined | null;
  includeOnBond: UsersInBond[] | undefined | null;
  rentScheduledStartDate: string | undefined | null;
  oneOffPaymentAmount: number | undefined | null;
  firstPaymentDate: string | undefined | null;
  depositAmount?: number;
  depositPaymentDate?: string | undefined | null;
  startScheduleFromPaidToDate?: boolean | undefined | null;
  leaseAttachments: LeaseAttachment[] | undefined | null;
  leaseSigningDocuments: LeaseSigningDocument[];
  leaseConfigurableDocuments: LeaseConfigurableDocument[];
  leaseDisclosureStatement: LeaseDisclosureStatementResponse;
  disclosureQuestionnaire: Record<string, string>;
  bondForms: BondForm[];
  uploadTemplates: LeaseUploadTemplate[];
  pdfFormTemplates: PdfFormTemplate[];
  leaseAgreement: LeaseTenancyAgreement | undefined | null;
  leased: boolean;
  vacateDate?: string | undefined | null;
  renewalType?: RenewalType | undefined | null;
  parentLeaseId: number | undefined | null;
  parentLeaseEndDate: string | undefined | null;
  archiveNotificationList: ArchiveNotificationList;
  signedManually?: boolean | undefined | null;
  status?: string | undefined | null;
  paymentReferenceId?: string | undefined | null;
  allRentReviewList: RentReviewData[];
  activeRentalAccount: RentalAccountInfo | null;
  rentReview: boolean;
  rentReviewLeaseResponse?: RentReviewLeaseResponse | null;
  agency?: AgencyDetails;
  isPartiallySigned?: boolean;
  services: LeaseServiceabilityResponse[];
  paymentInfo: PaymentInfo;
  paymentTransactions: PaymentTransactions[];
  paymentProviderType?: any | undefined | null;
  bondReferenceNumber: string | undefined | null;
  isLandlordSignatureRequired: boolean;
  rentalIncrease: RentalIncrease | null;
  shiftDueDateBy: number | undefined | null;
  customShiftDueDateBy: number | undefined | null;
  childLeaseStartDate: string | undefined | null;
  application: ApplicationResponse | undefined | null;
  leaseType: string;
  inclusions?: string | null;
  conditionReportDate?: string | null;
  bondDate?: string | null;
  disclosureStatementResponse: DisclosureStatementResponse | null;
  dateOfLastRentIncrease?: string;
}
